body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#home{
  background-image: url('../src/img/bg1.jpg');
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#about{
  background-image: url('../src/img/bg4.jpg');
}

#contact{
  background-image: url('../src/img/web_Bianca-118-Edit.jpg');
}

#lessons{
  background-image: url('../src/img/web_Bianca-27-Edit.jpg');
}

#teaching{
  background-image: url('../src/img/web_Bianca-27-Edit.jpg');
}

.caption-content {
  text-shadow: 4px 3px 5px rgba(0,0,0,0.6);
  color: white;
}

.caption-content{
  text-align: center;
}

body,html,#root,.App{
  height:100%;
}