@import url(http://fonts.googleapis.com/css?family=Montserrat:400);
@import url(http://fonts.googleapis.com/css?family=Inconsolata:400,700);
@import url(http://fonts.googleapis.com/css?family=Open+Sans:400italic,400,700);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#home{
  background-image: url(/static/media/bg1.93034865.jpg);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#about{
  background-image: url(/static/media/bg4.55726501.jpg);
}

#contact{
  background-image: url(/static/media/web_Bianca-118-Edit.a2371665.jpg);
}

#lessons{
  background-image: url(/static/media/web_Bianca-27-Edit.10892c3e.jpg);
}

#teaching{
  background-image: url(/static/media/web_Bianca-27-Edit.10892c3e.jpg);
}

.caption-content {
  text-shadow: 4px 3px 5px rgba(0,0,0,0.6);
  color: white;
}

.caption-content{
  text-align: center;
}

body,html,#root,.App{
  height:100%;
}
/* ------------------------------------------------------------------
General Styles
------------------------------------------------------------------- */

html {
	overflow-y: scroll;
	overflow-x: hidden;
	-ms-overflow-style: scrollbar;
}

body {
	background: #fff;
	font-family:"freight-big-pro";
	font-weight:400;
	color: #666;
	-webkit-font-smoothing: antialiased;
	font-size: 16px;
}

img {
	max-width: 100%;
	height: auto;
}

iframe {
	border: 0;
}

.leftauto {
	right: 0 !important;
	left: auto !important;
}

/* Selection */

::-webkit-selection{
	background: #000;
	color: #fff;
}

::selection{
	background: #000;
	color: #fff;
}

/* Transition elsements */

.form-control,
.navbar-custom,
.navbar-custom .navbar-brand {
transition: all 0.4s ease-in-out 0s;
}

a,
.btn,
.navbar a {
transition: all 0.125s ease-in-out 0s;
}

.team-detail,
.team-image:after,
.work-caption,
.work-image > img,
.work-image:after {
transition: all 0.3s ease-in-out 0s;
}

/* Reset border-radius */

.well,
.label,
.alert,
.progress,
.form-control,
.modal-content,
.panel-heading,
.panel-group .panel,
.nav-tabs > li > a,
.nav-pills > li > a {
border-radius: 2px;
}

/* Reset box-shadow */

.btn,
.well,
.panel,
.progress,
.progress-bar,
.form-control,
.form-control:hover,
.form-control:focus,
.navbar-custom .dropdown-menu {
	box-shadow: none;
}

/* ------------------------------------------------------------------
Form styles
------------------------------------------------------------------- */

.form-control {
	text-transform: uppercase;
	letter-spacing: 0.1em;
	font-size: 11px;
	height: 33px;
	border: 1px solid #b2b2b2;
	border-radius: 2px;
}

.form-control:focus {
	border-color: #CACACA;
}

/* Form sizes */

.input-lg,
.form-horizontal .form-group-lg .form-control {
	height: 43px;
	font-size: 13px;
}

.input-sm, .form-horizontal .form-group-sm .form-control {
	height: 29px;
	font-size: 10px;
}

/* ------------------------------------------------------------------
Button styles
------------------------------------------------------------------- */

.btn {
	padding: 8px 37px;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	font-size: 11px;
	border:1px solid rgba(0,0,0,0.5);
}

.btn.focus,
.btn.active.focus,
.btn.active:focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
	outline: 0;
}

.btn.btn-round {
	border-radius: 2px;
}

.btn.btn-circle {
	border-radius: 30px;
}

/* Button colors */

.btn.btn-g {
	background: transparent;
	border: 1px solid #b2b2b2;
	color: #666;
}

.btn.btn-g:hover,
.btn.btn-g:focus {
	background: #b2b2b2;
	color: #fff;
}

.btn.btn-border-d {
	background: transparent;
	border: 1px solid #000;
	color: #000;
}

.btn.btn-border-d:hover,
.btn.btn-border-d:focus {
	background: #000;
	color: #fff;
}

.btn.btn-d {
	background: rgba(0, 0, 0, 0.8);
	color: #fff;
}

.btn.btn-d:hover,
.btn.btn-d:focus {
	background: #000;
	color: #fff;
}

/* Button sizes */

.btn.btn-lg {
	padding: 12px 45px;
	font-size: 13px;
}

.btn.btn-sm {
	padding: 6px 25px;
	font-size: 10px;
	letter-spacing: 1px;
}

.btn.btn-xs {
	padding: 4px 19px;
	font-size: 10px;
	letter-spacing: 0;
}

.btn-list .btn {
	margin: 5px 0;
}

/* ------------------------------------------------------------------
Tabs and Accordions
------------------------------------------------------------------- */

.tab-content {
	padding: 20px 0;
}

.panel-title {
	font-size: 14px;
}

.panel-heading a {
	position: relative;
	display: block;
}

.panel-heading a:after {
	position: absolute;
	content: "\f106";
	top: 50%;
	right: 0px;
	line-height: 1;
	font-size: 14px;
	margin-top: -7px;
}

.panel-heading a.collapsed:after {
	content: "\f107";
}

/* ------------------------------------------------------------------
Progress bars
------------------------------------------------------------------- */

.progress {
	overflow: visible;
	height: 4px;
}

.progress-bar {
	position: relative;
}

.progress-bar.pb-dark {
	background: #000;
}

.progress-bar span {
	position: absolute;
	display: block;
	right: -0px;
	top: 6px;
	opacity: 0;
	line-height: 12px;
	font-size: 12px;
	color: #666;
	padding: 4px 0px;
}

.progress-bar span:after {
	display: inline-block;
	content: "%";
}

.progress-title {
	margin: 0 0 5px;
	text-transform: uppercase;
	font-size: 14px;
}

/* ------------------------------------------------------------------
Examples
------------------------------------------------------------------- */

.et-icons .box1 {
	border: 1px solid #e5e5e5;
	display: block;
	width: 25%;
	float: left;
	padding: 0;
	font-size: 13px;
	margin: -1px 0 0 -1px;
}

.et-icons .box1 > span {
	display: inline-block;
	border-right: 1px solid #e5e5e5;
	min-width: 60px;
	min-height: 60px;
	text-align: center;
	line-height: 60px;
	font-size: 28px;
	margin-right: 5px;
}

.fa-icons > div {
	padding: 0;
	border: 1px solid #e5e5e5;
	margin: -1px 0 0 -1px;
	font-size: 13px;
}

.fa-icons > div > i {
	display: inline-block;
	margin-right: 5px;
	min-width: 40px;
	min-height: 40px;
	border-right: 1px solid #f1f1f1;
	line-height: 40px;
	text-align: center;
	font-size: 14px;
}

.help-block ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

/* ------------------------------------------------------------------
Overlays
------------------------------------------------------------------- */

/* Dark background */

.bg-dark,
.bg-dark-30,
.bg-dark-50,
.bg-dark-90,
.bg-dark .module-title,
.bg-dark-30 .module-title,
.bg-dark-50 .module-title,
.bg-dark-90 .module-title {
	color: #fff;
}

.bg-dark {
	background: #111;
}

.bg-dark-30:before {
	position: absolute;
	background: rgba(0, 0, 0, .3);
	content: " ";
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
}

.bg-dark-50:before {
	position: absolute;
	background: rgba(0, 0, 0, .45);
	content: " ";
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
}

.bg-dark-90:before {
	position: absolute;
	background: rgba(0, 0, 0, .9);
	content: " ";
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
}

/* Light background */

.bg-light,
.bg-light-30,
.bg-light-50,
.bg-light-90 {
	color: #000;
}

.bg-light {
	background: rgba(234, 234, 234, 0.7);
}

.bg-light-30:before {
	position: absolute;
	background: rgba(255, 255, 255, .3);
	content: " ";
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
}

.bg-light-50:before {
	position: absolute;
	background: rgba(255, 255, 255, .5);
	content: " ";
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
}

.bg-light-90:before {
	position: absolute;
	background: rgba(255, 255, 255, .9);
	content: " ";
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
}

/* ------------------------------------------------------------------
Typography
------------------------------------------------------------------- */

a {
	color: #000;
}

a:hover, a:focus {
	text-decoration: none;
	color: #999;
	outline: 0;
}

.bg-dark a {
	color: #CDCDCD;
}

.bg-dark a:hover, .bg-dark a:focus {
	color: #000;
}

h1, h2, h3, h4, h5, h6 {
	line-height: 1.4;
	font-weight: 400;
}

p, ol, ul, blockquote {
	margin: 0 0 20px;
}

blockquote {
	border: 0;
	font-style: italic;
	font-size: 14px;
	padding: 0;
}

.font-alt {
	text-transform: uppercase;
	letter-spacing: 0.1em;
}

.font-inc {
	text-transform: uppercase;
	letter-spacing: 0.1em;
}

.font-serif {
}

.font-uppercase {
	text-transform: uppercase;
	font-style: normal;
}

.align-center {
	text-align: center;
}

.rotate {
	text-shadow: none !important;
}

/* ------------------------------------------------------------------
Navbar
------------------------------------------------------------------- */

.navbar-custom {
	background: #fff none repeat scroll 0 0;
	border: 0;
	border-color: #f5f5f5 !important;
	border-bottom: 1px solid #f5f5f5 !important;
	border-radius: 0;
	text-transform: uppercase;
	letter-spacing: 0.15em;
	font-size: 13px;
	z-index: 1000;
}

.navbar-custom .dropdown-menu {
	background: #fff;
	border: 1px solid #f5f5f5;
	border-radius: 0;
	padding: 0;
	box-shadow: none;
}

.navbar-custom .dropdown-menu .dropdown-menu {
	top: 0;
	left: 100%;
	right: auto;
	margin-top: -1px;
}

.navbar-custom .navbar-brand {
	letter-spacing: 0.1em;
	font-weight: 400;
	font-size: 20px;
	color: #000;
}

.navbar-custom .nav li > a {
	position: relative;
	color: #000;
}

.navbar-custom .nav > li > a:focus,
.navbar-custom .nav > li > a:hover,
.navbar-custom .nav .open > a,
.navbar-custom .nav .open > a:focus,
.navbar-custom .nav .open > a:hover,
.navbar-custom .dropdown-menu > li > a:focus,
.navbar-custom .dropdown-menu > li > a:hover {
	background: none;
	color: #999;
}

.navbar-custom .dropdown-menu > li > a:hover {
	background: #f5f5f5 !important;
}

.navbar-custom .dropdown-menu > li > a {
	border-bottom: 1px solid #f5f5f5 !important;
	padding: 11px 15px;
	letter-spacing: 0.15em;
	font-size: 11px;
	color: #666;
}

.navbar-custom .dropdown-menu > li:last-child > a {
	border: 0 !important;
}

.navbar-custom .dropdown-toggle:after {
	position: absolute;
	display: block;
	right: 0;
	top: 50%;
	margin-top: -5px;
	font-size: 9px;
	content: "\f107";
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.navbar-custom .navbar-nav > .dropdown > .dropdown-toggle {
	padding-right: 28px;
}

.navbar-custom .navbar-nav > .dropdown > .dropdown-toggle:after {
	position: absolute;
	display: block;
	right: 15px;
	top: 50%;
	margin-top: -5px;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: 9px;
	content: "\f107";
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.navbar-custom .navbar-toggle .icon-bar {
	background: #000;
}

/* -------------------------------------------------------------------
Home Section
------------------------------------------------------------------- */

.module-hero {
	position: relative;
	background-color: #fff;
	background-repeat: no-repeat;
	background-position: center center;
	width: 100%;
	z-index: 0;
background-size: cover;
}

.hs-title-size-1 {
	letter-spacing: 0.1em;
	font-size: 14px;
}

.hs-title-size-2 {
	letter-spacing: 0.2em;
	font-size: 18px;
}

.hs-title-size-3 {
	letter-spacing: 0.1em;
	font-size: 28px;
}

.hs-title-size-4 {
	letter-spacing: 0.2em;
	font-size: 36px;
}

/* Video */

.video-controls-box {
	position: absolute !important;
	bottom: 40px;
	left: 0;
	width: 100%;
	z-index: 1;
}

.video-controls-box a {
	visibility: hidden;
	display: inline-block;
	color: #fff;
	margin: 0 5px 0 0;
}

/* -------------------------------------------------------------------
Sections
------------------------------------------------------------------- */

.wrapper {
	position: relative;
	background: #fff;
	z-index: 1;
}

.module,
.module-small,
.module-header {
	position: relative;
	padding: 60px 0 120px 0;
	background-repeat: no-repeat;
	background-position: 50% 50%;
background-size: cover;
}

.module-small {
	padding:0;
}

.module-header {
	padding: 190px 0 140px;
}

.col-bg {
	background: rgba(255, 255, 255, .9);
	padding: 140px 60px;
}

/* Module title */

.module-title {
	position: relative;
	margin: 0 0 30px;
	letter-spacing: 0.15em;
	font-size: 30px;
	color: #000;
}

.module-subtitle {
	margin-bottom: 70px;
	font-size: 12px;
}

.module-title + .module-subtitle {
	margin-top: -35px;
}

/* Sections dividers */

.divider-w {
	border-top: 1px solid #f5f5f5;
	margin: 0;
}

/* ------------------------------------------------------------------
Social list
------------------------------------------------------------------- */

.social-list {
	list-style: none;
	padding: 0;
	margin: 0;
}

.social-list > li {
	display: inline-block;
	font-size: 24px;
	margin: 5px 5px 5px 0;
}

/* ------------------------------------------------------------------
Content boxes
------------------------------------------------------------------- */

.content-box {
	text-align: center;
	margin: 25px 0;
}

.content-box-icon {
	font-size: 30px;
}

.content-box-title {
	margin: 5px 0 0;
	font-size: 13px;
}

.content-box-text {
	margin: 15px 0 0;
}

/* ------------------------------------------------------------------
Counters
------------------------------------------------------------------- */

.counter-item {
	text-align: center;
}

.counter-number {
	font-size: 36px;
}

.counter-title {
	text-transform: uppercase;
	font-size: 14px;
}

/* ------------------------------------------------------------------
Google map
------------------------------------------------------------------- */

#map-section {
	position: relative;
	height: 450px;
	width: 100%;
}

#map {
	height: 100%;
	width: 100%;
}

#map img {
	max-width: none;
}

.ajax-response {
	text-align: center;
}

/* ------------------------------------------------------------------
Footer
------------------------------------------------------------------- */

.footer {
	/*background: #f5f5f5;*/
	background-color:#4e4e4e;
	padding: 20px 0;
	color:#6e6e6e;
}

.footer a{
  color:#f3f3f3;
}

.copyright {
	font-size: 14px;
}
.info-footer{
 background-color: #f1f1f1;
}
/* ------------------------------------------------------------------
Portfolio
------------------------------------------------------------------- */

/* Portfolio filter */

.filter {
	text-align: center;
	list-style: none;
	padding: 0;
	margin: 0 0 20px;
}

.filter > li {
	display: inline-block;
	padding: 5px 0;
	margin: 0 25px;
}

.filter > li a {
	text-transform: uppercase;
	font-size: 14px;
	color: rgba(102, 102, 102, 0.5);
}

.filter > li a:hover,
.filter > li a.current {
	color: #000;
}

/* Portfolio grid */

.works-grid {
	list-style: none;
	padding: 0;
	margin: 0;
}

.works-grid.works-grid-gut {
	margin: 0 0 0 -10px;
}

.works-grid.works-grid-gut .work-item {
	padding: 0 0 10px 10px;
}

.work-item {
	width: 50%;
	float: left;
	margin: 0;
}

.works-grid-3 .work-item {
	width: 33.333%;
}

.container > .works-grid-3 .work-item {
	width: 33.2%;
}

.works-grid-4 .work-item {
	width: 25%;
}

/* Portfolio item */

.work-item > a {
	position: relative;
	display: block;
	overflow: hidden;
}

.work-image {
	position: relative;
	overflow: hidden;
}

.work-image img {
	display: block;
	overflow: hidden;
	width: 100%;
}

.work-image:after {
	position: absolute;
	display: block;
	content: "";
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
}

.work-caption {
	width: 100%;
	padding: 0 20px;
	opacity: 0;
	position: absolute;
	bottom: 100%;
	left: 0;
	text-align: center;
	overflow: hidden;
}

.work-title {
	font-size: 18px;
	color: #fff;
	margin: 0 0 6px;
}

.work-descr {
	text-transform: uppercase;
	font-size: 14px;
	color: #999;
}

/* */
.works-grid-4 .work-title {
	font-size: 14px;
}

.works-grid-4 .work-descr {
	font-size: 12px;
}

/* Portfolio item hover */

.work-item:hover .work-image:after {
	background: rgba(0, 0, 0, .7);
}

.work-item:hover .work-image > img {
	transform: scale(1.1);
}

.work-item:hover .work-caption {
	bottom: 50%;
	opacity: 1;
	z-index: 3;
	transform: translateY(50%);
}

/* Work item white background */

.works-grid.works-hover-w .work-title {
	color: #000;
}

.works-grid.works-hover-w .work-descr {
	color: #666;
}

.works-grid.works-hover-w .work-item:hover .work-image:after {
	background: rgba(255, 255, 255, .8);
}

.works-grid.works-hover-g .work-image:after {
	opacity: 0;
}
.works-grid.works-hover-g .work-title,
.works-grid.works-hover-g .work-descr {
	color: #000;
}

.works-grid.works-hover-g .work-item:hover .work-image:after {
/* IE9 SVG, needs conditional override of 'filter' to 'none' */
background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMTAwJSI+CiAgICA8c3RvcCBvZmZzZXQ9IjAlIiBzdG9wLWNvbG9yPSIjZmZmZmRmIiBzdG9wLW9wYWNpdHk9IjAuOSIvPgogICAgPHN0b3Agb2Zmc2V0PSIyNSUiIHN0b3AtY29sb3I9IiNmZmZmZGYiIHN0b3Atb3BhY2l0eT0iMC45Ii8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmY2YwYjMiIHN0b3Atb3BhY2l0eT0iMC45Ii8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=); /* FF3.6+ */ /* Chrome,Safari4+ */ /* Chrome10+,Safari5.1+ */ /* Opera 11.10+ */ /* IE10+ */
background: linear-gradient(135deg,  rgba(255,255,223,0.8) 0%,rgba(255,255,223,0.8) 25%,rgba(252,240,179,0.8) 100%); /* W3C */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e6ffffdf', endColorstr='#e6fcf0b3',GradientType=1 ); /* IE6-8 fallback on horizontal gradient */
opacity: 1;
}

/* Portfolio single */

.work-details {
	margin: 0 0 20px;
}

.work-details-title {
	color: #000;
	margin: 0 0 20px;
}

.work-details ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.work-details ul > li {
	margin: 0 0 10px;
}

.work-title > i,
.work-title > span {
	font-size: 30px;
}

/* ------------------------------------------------------------------
Team
------------------------------------------------------------------- */

.team-item {
	position: relative;
	text-align: center;
}

.team-image {
	position: relative;
	overflow: hidden;
}

.team-image img {
	width: 100%;
}

.team-image:after {
	position: absolute;
	background: transparent;
	content: " ";
	display: block;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 1;
}

.team-detail {
	position: absolute;
	width: 100%;
	left: 0;
	bottom: 50%;
	opacity: 0;
	z-index: 2;
	padding: 20px;
	transform: translateY(50%);
}

.team-detail h5 {
	text-transform: uppercase;
	font-size: 14px;
}

.team-descr {
	margin: 20px 0 0;
}

.team-name {
	text-transform: uppercase;
	font-size: 14px;
	color: #000;
}

.team-role {
	text-transform: uppercase;
	font-size: 12px;
}

/* Team member hover */

.team-item:hover .team-image:after {
	background: rgba(255, 255, 255, .9);
}

.team-item:hover .team-detail {
	opacity: 1;
}

/* ------------------------------------------------------------------
Post
------------------------------------------------------------------- */

.post-columns .post {
	margin: 0 0 60px;
}

.post {
	margin: 0 0 80px;
}

.post-video,
.post-thumbnail,
.post-images-slider {
	margin: 0 0 20px;
}

.post-images-slider {
	position: relative;
}

.post-quote {
	background: #f5f5f5;
	text-align: center;
	padding: 20px;
}

.post-quote blockquote {
	margin: 0;
}

.post-header {
	margin: 0 0 20px;
}

.post-title {
	font-size: 18px;
	color: #000;
	margin: 0;
}

.post-columns .post-title {
	font-size: 14px;
}

.post-meta {
	font-size: 13px;
}

.post-more {
	text-transform: uppercase;
	letter-spacing: 0.15em;
	font-size: 13px;
}

/* Blog standart */

.post-header-small {
	position: relative;
	padding: 0 0 0 60px;
}

.post-icon {
	position: absolute;
	left: 0;
	top: 0;
	height: 48px;
	width: 48px;
	line-height: 48px;
	font-size: 36px;
}

.post-icon > i,
.post-icon > span {
	line-height: 48px;
}

/* ------------------------------------------------------------------
Pagination
------------------------------------------------------------------- */

.pagination {
	display: block;
	text-align: center;
	font-size: 14px;
}

.pagination a + a {
	padding-left: 20px;
}

/* -------------------------------------------------------------------
Comments and comment form
------------------------------------------------------------------- */

.comments,
.comment-form {
	margin: 80px 0 0;
}

.comments .comment-title,
.comment-form .comment-form-title {
	font-size: 18px;
	color: #000;
	margin: 0 0 40px;
}

.comment-author {
	text-transform: uppercase;
	font-size: 14px;
	margin: 0 0 10px;
}

.comment-avatar {
	width: 55px;
	float: left;
	margin-top: 10px;
}

.comment-avatar img {
	border-radius: 50%;
}

.comment-content {
	padding-top: 5px;
	margin-left: 75px;
	margin-bottom: 30px;
}

.comment-meta {
	text-transform: uppercase;
	font-size: 12px;
}

/* -------------------------------------------------------------------
Scroll to top
------------------------------------------------------------------- */

.scroll-up {
	position: fixed;
	display: none;
	bottom: 7px;
	right: 7px;
	z-index: 999;
}

.scroll-up a {
	background: #fff;
	display: block;
	height: 28px;
	width: 28px;
	text-align: center;
	line-height: 28px;
	font-size: 14px;
	color: #000;
	opacity: 0.6;
border-radius: 2px;
}

.scroll-up a:hover,
.scroll-up a:active {
	opacity: 1;
	color: #000;
}

/* ------------------------------------------------------------------
Preloader
------------------------------------------------------------------- */

.page-loader {
	position: fixed;
	background: #fff;
	bottom: 0;
	right: 0;
	left: 0;
	top: 0;
	z-index: 9998;
}

.loader {
	position: absolute;
	border-left: 2px solid #b2b2b2;
	border-top: 2px solid rgba(245, 245, 245, 0.8);
	border-right: 2px solid rgba(245, 245, 245, 0.8);
	border-bottom: 2px solid rgba(245, 245, 245, 0.8);
	height: 46px;
	width: 46px;
	left: 50%;
	top: 50%;
	margin: -23px 0 0 -23px;
	text-indent: -9999em;
	font-size: 10px;
	z-index: 9999;
	-webkit-animation: load 0.8s infinite linear;
	   -moz-animation: load 0.8s infinite linear;
		 ms-animation: load 0.8s infinite linear;
		  o-animation: load 0.8s infinite linear;
			animation: load 0.8s infinite linear;
}

.loader,
.loader:after {
	border-radius: 50%;
	width: 46px;
	height: 46px;
}

@-webkit-keyframes load {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes load {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

/* -------------------------------------------------------------------
Responsive Media Querries
------------------------------------------------------------------- */
@media(max-width:600px){
   .navbar-custom .navbar-brand {
     font-size: 13px;
   }
}
@media (min-width: 768px) {

	.align-right {
		text-align: right;
	}

	.navbar-transparent {
		background: transparent;
		border: 0 !important;
		border-color: #f5f5f5 !important;
		padding-bottom: 15px;
		padding-top: 15px;
	}

	.navbar-transparent .nav li > a,
	.navbar-transparent .navbar-brand {
		/*color: #fff;*/
		
	}
	
	

	.navbar-transparent .dropdown-menu > li > a {
		color: #666;
	}

	.nabar-dark .nav > li > a,
	.nabar-dark .navbar-brand {
		color: #000;
	}

	.navbar-custom .dropdown-menu .dropdown-toggle:after {
		position: absolute;
		display: block;
		right: 9px;
		top: 50%;
		margin-top: -6px;
		font: normal normal normal 14px/1 FontAwesome;
		font-size: 9px;
		content: "\f105";
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	.navbar-custom .dropdown-menu {
		position: absolute;
		display: block;
		visibility: hidden;
		opacity: 0;
	}

	.navbar-custom .open > .dropdown-menu {
		visibility: visible;
		opacity: 1;
	}

	.navbar-right .dropdown-menu {
		right: auto;
		left: 0;
	}

	/* Comments */

	.comment .comment {
		margin-left: 75px;
	}

}

@media (max-width: 991px) {

	/* Headers */

	.hs-title-size-3 {
		font-size: 24px;
	}

	.hs-title-size-4 {
		font-size: 32px;
	}

	.work-item,
	.works-grid-3 .work-item,
	.works-grid-4 .work-item,
	.works-grid-5 .work-item {
		width: 50%;
	}

}

@media (max-width: 767px) {

	/* Navbar */

	.navbar-custom .navbar-nav {
		margin-top: 0;
		margin-bottom: 0;
		
	}

	.navbar-custom li a:hover {
		background: #f5f5f5 !important;
	}

	.navbar-custom .navbar-nav .open .dropdown-menu .dropdown-header,
	.navbar-custom .navbar-nav .open .dropdown-menu > li > a {
		padding: 10px 25px;
	}

	.navbar-custom .navbar-nav .open .dropdown-menu .dropdown-menu .dropdown-header,
	.navbar-custom .navbar-nav .open .dropdown-menu .dropdown-menu > li > a {
		padding: 10px 35px;
	}

	.navbar-custom li a,
	.navbar-custom .dropdown-menu > li:last-child > a {
		border-bottom: 1px solid #f5f5f5 !important;
	}

	.navbar-custom .navbar-nav > .dropdown > .dropdown-toggle:after,
	.navbar-custom .dropdown-toggle:after,
	.navbar-custom .dropdown-menu .dropdown-toggle:after {
		right: 7px;
		content: "\f107";
	}

	.navbar-custom .navbar-nav > .open > .dropdown-toggle:after,
	.navbar-custom .nav > .open >.dropdown-toggle:after,
	.navbar-custom .dropdown-menu .dropdown.open .dropdown-toggle:after {
		content: "\f106";
	}

	.navbar-custom .navbar-collapse {
		border-top: 1px solid #f5f5f5;
		box-shadow: none;
	}

	/* Headers */

	.hs-title-size-1 {
		font-size: 12px;
	}

	.hs-title-size-2 {
		font-size: 16px;
	}

	.hs-title-size-3 {
		font-size: 20px;
	}

	.hs-title-size-4 {
		font-size: 24px;
	}

}

@media only screen and (max-width: 480px) {

	.work-item,
	.works-grid-3 .work-item,
	.works-grid-4 .work-item,
	.works-grid-5 .work-item {
		width: 100%;
	}


}


/* -------------------------------------------------------------------
Multi-columns-row
------------------------------------------------------------------- */

.multi-columns-row .first-in-row {
	clear: left;
}

.multi-columns-row .col-xs-6:nth-child(2n + 3) { clear: left; }
.multi-columns-row .col-xs-4:nth-child(3n + 4) { clear: left; }
.multi-columns-row .col-xs-3:nth-child(4n + 5) { clear: left; }
.multi-columns-row .col-xs-2:nth-child(6n + 7) { clear: left; }
.multi-columns-row .col-xs-1:nth-child(12n + 13) { clear: left; }

@media (min-width: 768px) {

	.multi-columns-row .col-xs-6:nth-child(2n + 3) { clear: none; }
	.multi-columns-row .col-xs-4:nth-child(3n + 4) { clear: none; }
	.multi-columns-row .col-xs-3:nth-child(4n + 5) { clear: none; }
	.multi-columns-row .col-xs-2:nth-child(6n + 7) { clear: none; }
	.multi-columns-row .col-xs-1:nth-child(12n + 13) { clear: none; }

	.multi-columns-row .col-sm-6:nth-child(2n + 3) { clear: left; }
	.multi-columns-row .col-sm-4:nth-child(3n + 4) { clear: left; }
	.multi-columns-row .col-sm-3:nth-child(4n + 5) { clear: left; }
	.multi-columns-row .col-sm-2:nth-child(6n + 7) { clear: left; }
	.multi-columns-row .col-sm-1:nth-child(12n + 13) { clear: left; }
}

@media (min-width: 992px) {

	.multi-columns-row .col-sm-6:nth-child(2n + 3) { clear: none; }
	.multi-columns-row .col-sm-4:nth-child(3n + 4) { clear: none; }
	.multi-columns-row .col-sm-3:nth-child(4n + 5) { clear: none; }
	.multi-columns-row .col-sm-2:nth-child(6n + 7) { clear: none; }
	.multi-columns-row .col-sm-1:nth-child(12n + 13) { clear: none; }

	.multi-columns-row .col-md-6:nth-child(2n + 3) { clear: left; }
	.multi-columns-row .col-md-4:nth-child(3n + 4) { clear: left; }
	.multi-columns-row .col-md-3:nth-child(4n + 5) { clear: left; }
	.multi-columns-row .col-md-2:nth-child(6n + 7) { clear: left; }
	.multi-columns-row .col-md-1:nth-child(12n + 13) { clear: left; }
}

@media (min-width: 1200px) {

	.multi-columns-row .col-md-6:nth-child(2n + 3) { clear: none; }
	.multi-columns-row .col-md-4:nth-child(3n + 4) { clear: none; }
	.multi-columns-row .col-md-3:nth-child(4n + 5) { clear: none; }
	.multi-columns-row .col-md-2:nth-child(6n + 7) { clear: none; }
	.multi-columns-row .col-md-1:nth-child(12n + 13) { clear: none; }

	.multi-columns-row .col-lg-6:nth-child(2n + 3) { clear: left; }
	.multi-columns-row .col-lg-4:nth-child(3n + 4) { clear: left; }
	.multi-columns-row .col-lg-3:nth-child(4n + 5) { clear: left; }
	.multi-columns-row .col-lg-2:nth-child(6n + 7) { clear: left; }
	.multi-columns-row .col-lg-1:nth-child(12n + 13) { clear: left; }

}

.hs-caption{
  z-index: 1;
}

.navbar-full{
  display: flex;
  margin:0;
  padding:0;
}

.navbar-full li{
  list-style-type: none;
  padding: 5px;
  margin:0;
}
